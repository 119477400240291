<template>
  <div class="section__lk-content container-shadow fill-white">
    <div class="section__lk-title">Электронная библиотека</div>
    <template v-if="books">
      <div class="section__lk-content-items">
        <div v-for="(chunk, index) in chunks" :key="index">
          <template v-if="index < currentPage">
            <div v-for="book in chunk" :key="book.id" class="section__lk-content-item" @click="openDocument(book)">
              <modal-pdf-view :id="'show-book-' + book.id" :link="pdf.link" :title="pdf.title" />
              <div class="section__lk-content-item-left">
                <img src="/pic/icon-book-open.svg" class="icon" />
                <div class="section__lk-content-item-separator"></div>
                <div class="section__lk-content-item-data">
                  <div class="section__lk-content-item-data-content">
                    <div>{{ book.title }}</div>
                  </div>
                  <div class="section__lk-content-item-data-title">
                    Читать <img src="/pic/profile/arrow-up-right-red.svg" />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="section__lk-content-items" v-if="currentPage < chunks.length">
        <div class="load-more__wrapper">
          <button class="btn btn-primary" @click="currentPage++">
            <span></span> Загрузить ещё
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import $ from 'jquery';
import 'bootstrap'
import ApiService from "../../../../services/api.service";
import "vue-pdf-app/dist/icons/main.css";
import {TokenService} from "../../../../services/token.service";
import _ from 'lodash'
import ModalPdfView from "@/components/ModalPdfView.vue";
export default {
  name: "Ebs",
  components: {
    ModalPdfView
  },
  data() {
    return {
      books: null,
      pdf: {
        title: null,
        link: null
      },
      currentPage: 1
    }
  },
  async mounted() {
    let books = (await ApiService.get('ebs', {
      params: {
        limit: 50
      }
    })).data
    if(books) {
      this.books = books.books
    }
  },
  computed: {
    chunks() {
      return _.chunk(this.books, 15)
    }
  },
  methods: {
    openDocument(document) {

      if(document.file && document.file.ext === 'epub') {
        window.open('/reader/index.html?bookPath=' + document.file.file, '_blank');
      }

      this.pdf = {title: null, link: null}

      let downloadUri = ApiService.baseURL() + '/api/ebs/' + document.id + '/download?access_token='+TokenService.getToken().replaceAll('"', '');

      if(document.auth) {
        let win = window.open(document.auth, '_blank');
        setTimeout( function () {
          win.close();
          window.location.href = document.link;
        }, 1000);
      } else {
        if(!document.link) {
          document.link = downloadUri
        }
        this.openAttach(document)
      }
    },
    async openAttach(document) {
      this.pdf.title = document.title
      if(document.link) {
        this.pdf.link = document.link
      }
      if(document.file) {
        this.pdf.link = document.file.file
      }

      if(this.pdf.link) {
        await $('#show-book-' + document.id).modal('show')
      }
    }
  }
}
</script>

<style scoped>
 .load-more__wrapper {
   margin-bottom: 4rem;
 }
</style>